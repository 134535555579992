<template>
  <page icon="users" :title="`Edit ${Naming.User} Persona`" v-bind="{ loading }" :loadingText="loadingText">
    <template #tools>
      <router-link exact class="button is-rounded is-primary" :to="{ name: 'user-persona-index' }">
        <icon icon="users"/>
        <span>{{ Naming.User }} Personas</span>
      </router-link>
      <router-link exact class="button is-rounded is-primary ml-1" :to="{ name: 'user-persona-transactions' }">
        <icon icon="receipt"/>
        <span>{{ Naming.User }} Persona Transaction</span>
      </router-link>
    </template>

    <form class="box">
      <div class="columns is-multiline">
        <div class="column mr-4">
          <text-input
              placeholder="ex: Region Group, Collective Area, etc…"
              required
              :value="persona.name"
              @input="name">
            Name
          </text-input>
        </div>
        <div class="columns mr-4 mt-2">
          <submit-button :working="loading" @submit="store" class="is-success is-medium" :disabled="!canSave"
                         left-icon="check">Update
            {{ Naming.User }} Persona
          </submit-button>
        </div>
      </div>
      <br/>
      <div v-if="showPage==='sites'">
        <div class="level mb-1">
          <div class="level-left justify-between level-item">
            <h3 class="is-size-5 level-item">Assign {{ Naming.Sites }} to {{ Naming.User }} Persona</h3>
            <div class="level-right">
              <action-button
                  v-if="showPage === 'sites'"
                  left-icon="plus-circle"
                  :disabled="!canAllSitesSave"
                  class="is-primary is-rounded m-1"
                  :class="{ 'has-tooltip-bottom': !canAllSitesSave }"
                  :data-tooltip="!canAllSitesSave ? `No available ${Naming.Sites} to add.` : null"
                  @click="addAllSites()"
                  :working="addingAllSites">
                Save All {{ Naming.Sites }} to this Persona
              </action-button>
              <action-button
                  @click="editTechnicianList"
                  left-icon="user"
                  class="button is-primary is-rounded">
                Edit {{ Naming.Users }}
              </action-button>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="grid has-5-columns gap-1">
            <!--ASSIGNED SITES-->
            <div class="is-2-columns">
              <div class="tab is-flex justify-between">
                <div>Assigned To {{ Naming.User }} Persona</div>
                <div>
                  Showing {{ this.assignedSitesList.length }} of {{ calculatedTotalAssignedSites }}
                </div>
              </div>
              <div class="search-box grid has-4-columns gap-1">
                <div class="is-flex is-3-columns">
                  <input
                      type="text"
                      v-model="assignedSitesSearch"
                      class="input"
                      placeholder="Search by name"
                  />
                  <div class="is-flex">
                    <action-button
                        v-if="assignedSitesSearch"
                        @click="clearAssignedSitesSearch()"
                        class="is-info-lighter">
                      <span>Clear</span>
                      <icon icon="times"/>
                    </action-button>
                  </div>
                  <div class="is-flex">
                    <action-button @click="searchAssignedSites()" class="ml-1 is-info-lighter">Search</action-button>
                  </div>
                </div>
                <div class="is-flex">
                  <action-button :disabled="loadingAssignedSites || !hasNoOpenJobs" @click="deselectAll()" :class="{ 'has-tooltip-bottom': !hasNoOpenJobs }"
                                 :data-tooltip="!hasNoOpenJobs ? `${Naming.Sites} with open ${Naming.Jobs.toLowerCase()} cant be removed` : null" class="is-danger">Deselect All {{Naming.Sites}} Showing</action-button>
                </div>
              </div>
              <draggable :list="assignedSitesList" group="subAssetSelector"
                         class="sub-asset-draggable has-background-white-bis is-fullheight"
                         handle="#handle"
                         v-if="!loadingAssignedSites"
              >
                <div v-if="hasAssignedSites" v-for="(site, index) in assignedSitesList" :key="site.id">
                  <assigned-site-item :available-items="false" :index="index"
                                      :site="site" :staged="addedSites"
                                      @removeFromSelected="removeSiteFromAssignedSites"/>
                </div>
                <div v-if="!hasAssignedSites" class="has-text-grey p-1">No {{ Naming.Sites }} yet. Select or drag from
                  available {{ Naming.Sites }}.
                </div>
                <div v-if="moreAssignedSites">
                  <div
                      class="persona-load-more-box has-text-grey is-flex-column align-items-center justify-between"
                      @click="loadMoreAssignedSites"
                  >
                    <div class="has-text-grey has-text-centered p-05">
                      Showing {{ this.assignedSitesList.length }} of {{ calculatedTotalAssignedSites }}
                    </div>
                    <icon class="is-size-5" icon="plus-circle"></icon>
                  </div>
                </div>
              </draggable>
            </div>

            <!--AVAILABLE SITES-->
            <div class="is-3-columns">
              <div class="tab available is-flex justify-between">
                <div class="">Available {{ Naming.Sites }}
                </div>
                <div>
                  Showing {{ availableSitesList.length }} of {{ calculatedTotalAvailableSites }}
                </div>
              </div>

              <div class="search-box grid has-4-columns gap-1">
                <div class="is-2-columns">
                  <data-selector
                      class="is-marginless"
                      searchable
                      v-model="available_client_id"
                      @input="searchAvailableSites"
                      left-icon="address-book"
                      label-key="legal_name"
                      value-key="id"
                      :items="clientList"
                      :prompt-label="`Filter by ${Naming.Client}`"
                      @search="searchClient"
                      :on-lose-focus="clearAvailableSitesClientFilter">
                    <action-button
                        v-if="available_client_id"
                        slot="right"
                        class="is-light"
                        @click="clearAvailableSitesClientFilter()">
                      <icon icon="times"/>
                    </action-button>
                  </data-selector>
                </div>
                <div class="is-flex is-2-columns">
                  <input
                      type="text"
                      v-model="availableSitesSearch"
                      class="input"
                      :placeholder="`Search by ${Naming.Site.toLocaleLowerCase()} name`"
                  />
                  <div class="is-flex">
                    <action-button
                        v-if="availableSitesSearch"
                        @click="clearAvailableSitesSearch()"
                        class="is-info-lighter">
                      <span>Clear</span>
                      <icon icon="times"/>
                    </action-button>
                  </div>
                  <div class="is-flex">
                    <action-button @click="searchAvailableSites()" class="ml-1 is-info-lighter">Search</action-button>
                  </div>
                </div>
              </div>
              <div class="search-box grid has-3-columns gap-1">
                <div class="is-flex">
                  <action-button :disabled="loadingMoreSites" @click="selectAll()" class="is-info-lighter">Select All Showing {{ Naming.Sites }}</action-button>
                  <action-button left-icon="sort" @click="sortSites('name')">{{ Naming.Site }} Name</action-button>
                  <action-button left-icon="sort" @click="sortSites('client')">{{ Naming.Client }} Name</action-button>
                </div>
              </div>

              <draggable :list="availableSitesList" group="subAssetSelector" class="sub-asset-draggable" handle="#handle">
                <div v-for="(availableSite, index) in availableSitesList" :key="availableSite.id">
                  <site-item v-if="!loadingSites" :is-available-items="true"
                             :index="index" class="available-item"
                             :staged="removedSites"
                             :site="availableSite" @pushToSelected="pushSiteToSelected"/>
                </div>
                <loader v-if="loadingMoreSites" v-bind="{ loadingMoreSites }"
                        :text="`Loading More ${Naming.Sites}`"></loader>
                <div v-if="moreSitesAvailable">
                  <div
                      class="persona-load-more-box has-text-grey is-flex-column align-items-center justify-between"
                      @click="loadMoreSites()"
                  >
                    <div class="has-text-grey has-text-centered p-05">
                      Showing {{ availableSitesList.length }} of {{ calculatedTotalAvailableSites }}
                    </div>
                    <icon class="is-size-5" icon="plus-circle"></icon>
                  </div>
                </div>
                <!--placeholder to give height when no subasset items-->
                <div class="p-2">&nbsp</div>
              </draggable>
              <div v-if="!availableSitesList">
                <div class="has-text-grey p-05">No additional available {{ Naming.Sites.toLowerCase() }}.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showPage==='technicians'">
        <div class="level mb-1">
          <div class="level-left justify-between level-item">
            <h3 class="is-size-5 level-item">Assign {{ Naming.Users }} to {{ Naming.User }} Persona</h3>
            <action-button
                @click="editSiteList"
                left-icon="building"
                class="button is-primary is-rounded mr-1">
              Edit {{ Naming.Sites }}
            </action-button>
          </div>
        </div>
        <div class="box">
          <div class="grid has-5-columns gap-1">
            <!--ASSIGNED TECHNICIANS-->
            <div class="is-2-columns">
              <div class="tab is-flex justify-between">
                <div>Assigned To {{ Naming.User }} Persona</div>
                <div>
                  Showing {{ assignedTechniciansList.length }} of {{ calculatedTotalAssignedTechnicians }}
                </div>
              </div>
              <draggable :list="assignedTechniciansList" group="subAssetSelector"
                         class="sub-asset-draggable has-background-white-bis is-fullheight">
                <div v-if="hasSelectedTechnicians" v-for="(technician, index) in assignedTechniciansList"
                     :key="technician.id">
                  <assigned-technician-item :available-items="false" :index="index"
                                            :technician="technician" :staged="addedTechnicians"
                                            @removeFromSelected="removeTechnicianFromSelectedTechnicians"/>
                </div>
                <div v-if="!hasSelectedTechnicians" class="has-text-grey p-1">No {{ Naming.Users }} yet. Select or
                  drag from
                  available {{ Naming.Users }}.
                </div>
                <div v-if="moreAssignedTechnicians">
                  <div
                      class="persona-load-more-box has-text-grey is-flex-column align-items-center justify-between"
                      @click="loadMoreAssignedTechnicians()"
                  >
                    <div class="has-text-grey has-text-centered p-05">
                      Showing {{ assignedTechniciansList.length }} of {{ calculatedTotalAssignedTechnicians }}
                    </div>
                    <icon class="is-size-5" icon="plus-circle "></icon>
                  </div>
                </div>
              </draggable>
            </div>

            <!--AVAILABLE TECHNICIANS-->
            <div class="is-3-columns">
              <div class="tab available is-flex justify-between">
                <div>Available {{ Naming.Users }}
                </div>
                <div>
                  Showing {{ availableTechniciansList.length }} of {{ calculatedTotalAvailableTechnicians }}
                </div>
              </div>

              <div>
                <div class="search-box grid has-4-columns gap-1">
                  <div class="is-flex is-3-columns">
                    <input
                        type="text"
                        v-model="availableTechniciansSearch"
                        class="input"
                        placeholder="Search by name"
                        @input="debouncedSearchTechnicians"
                    />
                    <div class="is-flex">
                      <action-button
                          v-if="availableTechniciansSearch"
                          @click="clearTechnicianSearch()"
                          class="is-info-lighter">
                        <span>Clear</span>
                        <icon icon="times"/>
                      </action-button>
                    </div>
                  </div>
                  <action-button @click="selectAllTechnicians()" :disabled="loadingMoreTechnicians" class="is-info-lighter">Select All Showing {{ Naming.Users }}</action-button>
                  <action-button left-icon="sort" @click="sortTechnicians('full_name')">{{Naming.User}} Name</action-button>
                </div>

                <div v-if="!technicianListLoading">
                  <draggable :list="availableTechniciansList" group="subAssetSelector"
                             class="sub-asset-draggable">
                    <div v-for="(availableTechnician, index) in availableTechniciansList"
                         :key="availableTechnician.id">
                      <technician-item v-if="!loadingTechnicians" :is-available-items="true"
                                       :index="index" class="available-item"
                                       :technician="availableTechnician" @pushToSelected="pushTechnicianToSelected"/>
                    </div>
                    <loader v-if="loadingMoreTechnicians" v-bind="{ loadingMoreTechnicians }"
                            :text="`Loading More ${Naming.Users}`"></loader>
                    <div v-if="moreTechniciansAvailable">
                      <div
                          class="persona-load-more-box has-text-grey is-flex-column align-items-center justify-between"
                          @click="loadMoreTechnicians()"
                      >
                        <div class="has-text-grey has-text-centered p-05">
                          Showing {{ availableTechniciansList.length }} of {{ calculatedTotalAvailableTechnicians }}
                        </div>
                        <icon class="is-size-5" icon="plus-circle "></icon>
                      </div>
                    </div>
                    <!--placeholder to give height when no technician items-->
                    <div class="p-2">&nbsp</div>
                  </draggable>
                  <div v-if="!availableTechniciansList">
                    <div class="has-text-grey p-05">No additional available {{
                        Naming.Users.toLowerCase()
                      }}.
                    </div>
                  </div>
                </div>
                <loader v-if="technicianListLoading" v-bind="{ technicianListLoading }"
                        :text="`Loading ${Naming.Users}`"></loader>
                <!-- end draggable technician list -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </form>

  </page>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import ChangeZone from "@/views/asset/manager/partials/ChangeZone.vue"
import SiteItem from "@/views/user/personas/partials/SiteItem.vue"
import {common as backend} from "@/api"
import AssignedSiteItem from "@/views/user/personas/partials/AssignedSiteItem.vue"
import AssignedTechnicianItem from "@/views/user/personas/partials/AssignedTechnicianItem.vue"
import TechnicianItem from "@/views/user/personas/partials/TechnicianItem.vue"
import {debounce} from "lodash"
import Search from "@/utils/search"

export default {
  components: {TechnicianItem, AssignedTechnicianItem, AssignedSiteItem, SiteItem, ChangeZone},

  data: () => ({
    loading: false,
    availableSitesSearch: '',
    assignedSitesSearch: '',
    availableTechniciansSearch: '',
    available_client_id: '',
    loadingSites: false,
    loadingAssignedSites:false,
    loadingMoreSites: false,
    loadingTechnicians: false,
    loadingMoreTechnicians: false,
    technicianListLoading: false,
    sortDescending: {
      name: false,
      created_at: false,
    },
    showPage: 'sites',
    addingAllSites: false,
    processingTextSiteCountLimit: 1000,
    addedFilteredSites: [],
    removedFilteredSites: [],
    removedFilteredTechnicians: []
  }),

  async created() {
    await this.loadUserPersona()
    await this.loadAvailableTechnicians()
    await this.loadAssignedTechnicians()
    await this.loadAvailableSites()
    await this.loadAssignedSites()
    if (this.available_client_id ) {
      await this.$store.dispatch('client/searchClient', { id: parseInt(this.available_client_id) })
    } else {
      Search.searchByUri('', 'client/searchClient', this.$store, 0)
    }
  },

  beforeDestroy() {
    this.clearUserPersona()
  },

  methods: {
    ...mapMutations('client', [
      'setClientList',
    ]),
    ...mapMutations('userPersonas', [
      'clearUserPersona',
      'name',
      'sites',
    ],),
    debouncedSearchTechnicians: debounce(function () {
      this.loadingMoreSites = true
      this.searchAvailableTechnicians()
    }, 1000),
    async store() {
      this.loading = true
      let payload = {
        persona_id: this.persona.id,
        name: this.persona.name,
        added_sites: this.addedSites.map((site) => {
          return {site_id: site.id}
        }),
        removed_sites: this.removedSites.map((site) => {
          return {site_id: site.id}
        }),
        added_technicians: this.addedTechnicians.map((technician) => {
          return {id: technician.id}
        }),
        removed_technicians: this.removedTechnicians.map((technician) => {
          return {id: technician.id}
        })
      }
      await this.$store.dispatch('userPersonas/update', payload).then(async userPersona => {
        this.$toast.success(this.Convert(this.$lang.userPersona.updated))
        this.loading = false

        if (await this.$alert(
            "Persona",
            "The Persona has been submitted for updating. It may take some time to process. Please review the Transactions to see the state."
        )) {
          this.$router.back()
        }
      }).catch(error => {
        this.loading = false
        if(error.response?.status === 422) {
          this.$toast.warning("The Name already exists for this Persona.")
        }else {
          this.$toast.error("An error occurred, please try again or contact support.")
        }
      })
    },
    searchClient(text) {
      Search.debouncedSearchByUri(text, 'client/searchClient', this.$store)
    },
    clearAvailableSitesClientFilter() {
      this.available_client_id = ''
      this.removedFilteredSites = []
      this.loadAvailableSites()
    },
    async confirmAdd() {

      return this.$confirm({
        title: `Adding All ${this.Naming.Sites} to Persona`,
        message: `Are you sure you want to add all ${this.Naming.Sites} to the Persona?`,
        confirmText: "Add Sites",
        cancelText: "Cancel",
        confirmClass: 'is-success',
        cancelClass: 'is-info',
      })
          .catch(() => {
            this.$whoops()
          })
    },
    async addAllSites() {
      const shouldAdd = await this.confirmAdd()
      if(shouldAdd === 1) {

        this.addingAllSites = true
        this.loading = true
        const payload = {
          persona: this.$route.params.persona,
          name: this.persona.name,
        }

        await this.$store.dispatch('userPersonas/updateAllSites', payload)
            .then(() =>
                this.loadAssignedSites()
            )
            .catch((error) => {
              console.log(error)
              this.$whoops()
            }).finally(() => {
              this.addingAllSites = false
              this.loading = false
            })
      }
    },
    async fetchData(stateKey, path, params, loadingKey) {
      this[loadingKey] = true;
      this[stateKey].data = [];
      await backend.loadPath(
          { path, params },
          ({ data }) => {
            this[stateKey].links.next = data.links.next;
            this[stateKey].meta = data.meta;
            this[stateKey].data.push(...data.data);
          },
          error => console.error(`Error fetching ${stateKey}:`, error)
      ).finally(() => {
        this[loadingKey] = false;
      });
    },
    async loadFromStore(action, params, loadingKey) {
      this[loadingKey] = true;
      await this.$store.dispatch(action, params).finally(() => {
        this[loadingKey] = false;
      });
    },
    async searchAvailableSites() {
      await this.fetchData(
          'availableSites',
          this.availableSites.links.first,
          this.getAvailableSitesPayload(),
          'loadingMoreSites'
      );
    },
    async searchAssignedSites() {
      await this.fetchData(
          'assignedSites',
          this.assignedSites.links.first,
          { assigned_sites_search: this.assignedSitesSearch },
          'loadingMoreSites'
      );
    },
    async searchAvailableTechnicians() {
      await this.fetchData(
          'availableTechnicians',
          this.availableTechnicians.links.first,
          this.getAvailableSitesPayload(),
          'technicianListLoading'
      );
    },
    async loadAvailableSites() {
      await this.loadFromStore(
          'userPersonas/loadAvailableSites',
          { persona: this.$route.params.persona, params: this.getAvailableSitesPayload() },
          'loadingSites'
      );
    },
    async loadAssignedSites() {
      await this.loadFromStore(
          'userPersonas/loadAssignedSites',
          { persona: this.$route.params.persona, params: { assigned_sites_search: this.assignedSitesSearch } },
          'loadingAssignedSites'
      );
    },
    async loadAvailableTechnicians() {
      await this.loadFromStore(
          'userPersonas/availableTechnicians',
          { persona: this.$route.params.persona, params: this.getAvailableSitesPayload() },
          'technicianListLoading'
      );
    },
    async loadAssignedTechnicians() {
      await this.loadFromStore(
          'userPersonas/assignedTechnicians',
          { persona: this.$route.params.persona, params: { assigned_technician_search: this.availableTechnicians } },
          'technicianListLoading'
      );
    },
    getAvailableSitesPayload() {
      return {
        available_sites_search: this.availableSitesSearch,
        available_client_id: this.available_client_id,
        available_technicians_search: this.availableTechniciansSearch,
      }
    },
    removeSiteFromAssignedSites(site) {
      if (this.assignedSitesSearch) {
        const filteredAddedIndex = this.addedFilteredSites.findIndex(addedFilteredSite => addedFilteredSite.id === site.id)
        if(filteredAddedIndex > -1) {
          this.addedFilteredSites.splice(filteredAddedIndex, 1)
        }
      }

      const addedIndex = this.addedSites.findIndex(addedSite => addedSite.id === site.id)
      if (addedIndex > -1) {
        return this.addedSites.splice(addedIndex, 1)
      }

      const isRemoved = this.removedSites.some(removedSite => removedSite.id === site.id)
      if (!isRemoved) {
        this.removedFilteredSites.push(site)
        this.removedSites.push(site)
      }
    },
    removeTechnicianFromSelectedTechnicians(technician) {
      const addedIndex = this.addedTechnicians.findIndex(addedTechnician => addedTechnician.id === technician.id)
      if (addedIndex > -1) {
        return this.addedTechnicians.splice(addedIndex, 1)
      }
      const isRemoved = this.removedTechnicians.some(removedTechnician => removedTechnician.id === technician.id)
      if (!isRemoved) {
        this.removedFilteredTechnicians.push(technician)
        this.removedTechnicians.push(technician)
      }
    },
    clearAvailableSitesSearch() {
      this.availableSitesSearch = ''
      this.removedFilteredSites = []
      this.loadAvailableSites()
    },
    clearAssignedSitesSearch() {
      this.assignedSitesSearch = ''
      this.addedFilteredSites = []
      this.loadAssignedSites()
    },
    clearTechnicianSearch() {
      this.availableTechniciansSearch = ''
      this.loadAvailableTechnicians()
    },
    async selectAll() {
      this.availableSitesList.forEach(site => {
        const removedIndex = this.removedSites.findIndex(removedSite => removedSite.id === site.id)
        if (removedIndex !== -1) {
          return this.removedSites.splice(removedIndex, 1)
        }
        const alreadyAdded = this.addedSites.some(addedSite => addedSite.id === site.id)
        if (!alreadyAdded) {
          this.addedSites.push(site)
        }
      })
      if (this.moreSitesAvailable) {
        this.loadMoreSites()
      }
    },
    async deselectAll() {
      this.assignedSitesList.forEach(site => {
        if (site.open_job_count > 0) {
          return
        }
        const addedIndex = this.addedSites.findIndex(addedSite => addedSite.id === site.id)
        if (addedIndex !== -1) {
          return this.addedSites.splice(addedIndex, 1)
        }
        const alreadyRemoved = this.removedSites.some(removedSite => removedSite.id === site.id)
        if (!alreadyRemoved) {
          this.removedSites.push(site)
        }
      })
      if (this.moreAssignedSites) {
        this.loadMoreAssignedSites()
      }
    },
    selectAllTechnicians() {
      this.availableTechniciansList.forEach(technician => {
        const removedIndex = this.removedTechnicians.findIndex(removedTechnician => removedTechnician.id === technician.id)
        if (removedIndex !== -1) {
          return this.removedTechnicians.splice(removedIndex, 1)
        }
        const alreadyAdded = this.addedTechnicians.some(addedTechnician => addedTechnician.id === technician.id)
        if (!alreadyAdded) {
          this.addedTechnicians.push(technician)
        }
      })
      if (this.moreTechniciansAvailable) {
        this.loadMoreTechnicians()
      }
    },
    sortSites(key) {
      let isDescending = this.sortDescending[key]
      this.availableSites.data = this.sortArray(this.availableSites?.data, key, isDescending)
      this.sortDescending[key] = !this.sortDescending[key]
    },
    sortArray(array, key, descending = false) {
      return array.sort((a,b) => {
        let valueA = a[key]
        let valueB = b[key]

        if(typeof valueA === 'string') {
          valueA = valueA?.toLowerCase()
          valueB = valueB?.toLowerCase()
        }

        return descending ? (valueB?.localeCompare(valueA)) : (valueA?.localeCompare(valueB))
      })
    },
    sortTechnicians(key) {
      let isDescending = this.sortDescending[key]
      this.availableTechnicians.data = this.sortArray(this.availableTechnicians?.data, key, isDescending)
      this.sortDescending[key] = !this.sortDescending[key]
    },
    pushSiteToSelected(site) {
      if (this.availableSitesSearch || this.available_client_id) {
        const filteredRemovedIndex = this.removedFilteredSites.findIndex(removedFilteredSite => removedFilteredSite.id === site.id)
        if(filteredRemovedIndex > -1) {
          this.removedFilteredSites.splice(filteredRemovedIndex, 1)
        }
      }

      const removedIndex = this.removedSites.findIndex(removedSite => removedSite.id === site.id)
      if (removedIndex > -1) {
        return this.removedSites.splice(removedIndex, 1)
      }
      const isAdded = this.addedSites.some(addedSite => addedSite.id === site.id)
      if (!isAdded) {
        this.addedFilteredSites.push(site)
        this.addedSites.push(site)
      }
    },
    pushTechnicianToSelected(technician) {
      if (this.availableTechniciansSearch) {
        const filteredRemovedIndex = this.removedFilteredTechnicians.findIndex(removedFilteredTechnician => removedFilteredTechnician.id === technician.id)
        if(filteredRemovedIndex > -1) {
          this.removedFilteredTechnicians.splice(filteredRemovedIndex, 1)
        }
      }

      const removedIndex = this.removedTechnicians.findIndex(removedTechnician => removedTechnician.id === technician.id)
      if (removedIndex > -1) {
        return this.removedTechnicians.splice(removedIndex, 1)
      }
      const isAdded = this.addedTechnicians.some(addedTechnician => addedTechnician.id === technician.id)
      if (!isAdded) {
        this.addedTechnicians.push(technician)
      }
    },
    async fetchMoreData(stateKey, loadingKey, params) {
      this[loadingKey] = true;
      await backend.loadPath(
          { path: this[stateKey].links.next, params },
          ({ data }) => {
            this[stateKey].links.next = data.links.next;
            this[stateKey].meta = data.meta;
            this[stateKey].data.push(...data.data);
          },
          error => console.error(`Error loading more ${stateKey}:`, error)
      ).finally(() => {
        this[loadingKey] = false;
      });
    },
    async loadMoreSites() {
      await this.fetchMoreData(
          'availableSites',
          'loadingMoreSites',
          this.getAvailableSitesPayload()
      );
    },
    async loadMoreAssignedSites() {
      await this.fetchMoreData(
          'assignedSites',
          'loadingAssignedSites',
          { assigned_sites_search: this.assignedSitesSearch }
      );
    },
    async loadMoreTechnicians() {
      await this.fetchMoreData(
          'availableTechnicians',
          'loadingMoreTechnicians',
          this.getAvailableSitesPayload()
      );
    },
    async loadMoreAssignedTechnicians() {
      await this.fetchMoreData(
          'assignedTechnicians',
          'loadingMoreTechnicians',
          { available_technicians_search: this.availableTechniciansSearch }
      );
    },
    debounce(callback, delay = 300) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          callback.apply(this, args)
        }, delay)
      }
    },
    async loadUserPersona() {
      this.loading = true
      await this.$store.dispatch('userPersonas/loadPersona', this.$route.params.persona)
      this.loading = false
    },
    editTechnicianList() {
      this.showPage = 'technicians'
    },
    editSiteList() {
      this.showPage = 'sites'
    },
    updateFilteredSitesLists() {
      this.addedFilteredSites = this.computedAddedFilteredSites
      this.removedFilteredSites = this.computedRemovedFilteredSites
    },
    updateFilteredTechniciansLists() {
      this.removedFilteredTechnicians = this.computedRemovedFilteredTechnicians
    },
  },

  computed: {
    ...mapState('userPersonas', [
      'removedSites',
      'addedSites',
      'removedTechnicians',
      'addedTechnicians'
    ]),
    ...mapGetters('userPersonas', [
      'persona',
      'assignedSites',
      'availableSites',
      'assignedTechnicians',
      'availableTechnicians',
    ]),
    ...mapGetters('client', [
      'clientList'
    ]),
    assignedSitesList() {
      const addedSites = this.assignedSitesSearch ? this.addedFilteredSites : this.addedSites
      const removedSites = this.availableSitesSearch || this.available_client_id ? this.removedFilteredSites : this.removedSites
      let combined = [...this.assignedSites.data, ...addedSites]
      return combined.filter(
          site => !removedSites.some(removedSite => removedSite.id === site.id)
      )
    },
    availableSitesList() {
      const addedSites = this.assignedSitesSearch ? this.addedFilteredSites : this.addedSites
      const removedSites = this.availableSitesSearch || this.available_client_id ? this.removedFilteredSites : this.removedSites
      let combined = [...this.availableSites.data, ...removedSites]
      return combined.filter(
          site => !addedSites.some(addedSite => addedSite.id === site.id)
      )
    },
    assignedTechniciansList() {
      let combined = [...this.assignedTechnicians.data, ...this.addedTechnicians]
      return combined.filter(
          site => !this.removedTechnicians.some(removedTechnician => removedTechnician.id === site.id)
      )
    },
    availableTechniciansList() {
        const removedTechnicians = this.availableTechniciansSearch ? this.removedFilteredTechnicians : this.removedTechnicians
      let combined = [...this.availableTechnicians.data, ...removedTechnicians]
      return combined.filter(
          site => !this.addedTechnicians.some(addedTechnician => addedTechnician.id === site.id)
      )
    },
    hasAssignedSites() {
      return this.assignedSitesList.length > 0
    },
    hasSelectedTechnicians() {
      return this.assignedTechniciansList?.length > 0
    },
    calculatedTotalAvailableSites() {
      let availableSitesCount = this.availableSites?.data?.length + this.removedSites.length - this.addedSites.length
      if (this.moreSitesAvailable) {
        const toBeLoaded = this.availableSites?.meta?.total - this.availableSites?.meta?.to
        return availableSitesCount + toBeLoaded
      }
      return availableSitesCount
    },
    calculatedTotalAssignedSites() {
      let assignedSitesCount = this.assignedSites?.data?.length + this.addedSites.length - this.removedSites.length
      if (this.moreAssignedSites) {
        const toBeLoaded = this.assignedSites?.meta?.total - this.assignedSites?.meta?.to
        return assignedSitesCount + toBeLoaded
      }
      return assignedSitesCount
    },
    calculatedTotalAvailableTechnicians() {
      let availableTechniciansCount = this.availableTechnicians?.data?.length + this.removedTechnicians.length - this.addedTechnicians.length
      if (this.moreTechniciansAvailable) {
        const toBeLoaded = this.availableTechnicians?.meta?.total - this.availableTechnicians?.meta?.to
        return availableTechniciansCount + toBeLoaded
      }

      return availableTechniciansCount
    },
    calculatedTotalAssignedTechnicians() {
      let assignedTechniciansCount = this.assignedTechnicians?.data?.length + this.addedTechnicians.length - this.removedTechnicians.length
      if (this.moreAssignedTechnicians) {
        const toBeLoaded = this.assignedTechnicians?.meta?.total - this.assignedTechnicians?.meta?.to
        return assignedTechniciansCount + toBeLoaded
      }
      return assignedTechniciansCount
    },
    moreSitesAvailable() {
      return this.availableSites?.links?.next?.length > 0
    },
    moreAssignedSites() {
      return this.assignedSites?.links?.next?.length > 0
    },
    moreTechniciansAvailable() {
      return this.availableTechnicians?.links?.next?.length > 0
    },
    moreAssignedTechnicians() {
      return this.assignedTechnicians?.links?.next?.length > 0
    },
    computedAddedFilteredSites() {
      return this.addedSites.filter(site => {
        const matchesSiteName = this.assignedSitesSearch
            ? site.name.toLowerCase().includes(this.assignedSitesSearch.toLowerCase())
            : true
        const matchesClientName = this.assignedSitesSearch
            ? site.client.toLowerCase().includes(this.assignedSitesSearch.toLowerCase())
            : true
        return matchesSiteName && matchesClientName
      })
    },
    computedRemovedFilteredSites() {
      return this.removedSites.filter(site => {
        const matchesName = this.availableSitesSearch
            ? site.name.toLowerCase().includes(this.availableSitesSearch.toLowerCase())
            : true
        const matchesClient = this.available_client_id
            ? site.client_id === this.available_client_id
            : true
        return matchesName && matchesClient
      })
    },
    computedRemovedFilteredTechnicians() {
      return this.removedSites.filter(site => {
        const matchesName = this.availableSitesSearch
            ? site.name.toLowerCase().includes(this.availableSitesSearch.toLowerCase())
            : true
        const matchesClient = this.available_client_id
            ? site.client_id === this.available_client_id
            : true
        return matchesName && matchesClient
      })
    },
    hasNoOpenJobs() {
      return this.assignedSitesList.some(site => site.open_job_count === 0);
    },
    hasSiteChanges() {
      return this.addedSites.length > 0 || this.removedSites.length > 0
    },
    hasTechnicianChanges() {
      return this.addedTechnicians.length > 0 || this.removedTechnicians.length > 0
    },
    canAllSitesSave() {
      return this.hasValidNameLength && this.availableSites.data.length > 0
    },
    hasValidNameLength() {
      return this.persona.name.length > 2
    },
    canSave() {
      return this.hasValidNameLength || this.hasSiteChanges || this.hasTechnicianChanges
    },
    changedSitesLength() {
      return this.addedSites.length + this.removedSites.length
    },
    loadingText() {
      if(this.changedSitesLength > this.processingTextSiteCountLimit) {
        return `Processing ${this.changedSitesLength} ${ this.Naming.Sites }, this may take a while.`
      }
      return "Loading..."
    },
  },
  watch: {
    availableSitesSearch() {
      this.updateFilteredSitesLists()
    },
    available_client_id() {
      this.updateFilteredSitesLists()
    },
    assignedSitesSearch() {
      this.updateFilteredSitesLists()
    },
    availableTechniciansSearch() {
      this.updateFilteredTechniciansLists()
    }
  },
}
</script>
