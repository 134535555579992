<template>
  <page icon="users" :title="`New ${Naming.User} Persona`" v-bind="{ loading }" :loadingText="loadingText">
    <template #tools>
      <router-link exact class="button is-rounded is-primary" :to="{ name: 'user-persona-index' }">
        <icon icon="users"/>
        <span>{{ Naming.User }} Personas</span>
      </router-link>
      <router-link exact class="button is-rounded is-primary ml-1" :to="{ name: 'user-persona-transactions' }">
        <icon icon="receipt"/>
        <span>{{ Naming.User }} Persona Transaction</span>
      </router-link>
    </template>
    <form class="box">
      <div class="columns is-multiline">
        <div class="column mr-4">
          <text-input
              placeholder="ex: Region Group, Collective Area, etc…"
              required
              :value="persona.name"
              @input="name">
            Name
          </text-input>
        </div>
        <div class="columns mr-4 mt-2">
          <submit-button :working="loading" @submit="store" class="is-success is-medium" :disabled="!canSave"
                         left-icon="check">Create
            {{ Naming.User }} Persona
          </submit-button>
          <action-button
              left-icon="plus-circle"
              :disabled="!validPersonaNameEntered"
              class="is-pulled-right is-primary ml-1 is-medium"
              :class="{ 'has-tooltip-bottom': !validPersonaNameEntered }"
              :data-tooltip="!validPersonaNameEntered ? `Need to add a persona name.` : null"
              @click="addAllSites()"
              :working="addingAllSites">
            Save All {{ Naming.Sites }} to this Persona
          </action-button>
        </div>
      </div>
      <br/>
      <div class="level mb-1">
        <div class="level-left">
          <h3 class="is-size-5 level-item">Assign {{ Naming.Sites }} to {{ Naming.User }} Persona</h3>
        </div>
      </div>
      <div class="box">
        <div class="grid has-5-columns gap-1">
          <!--ASSIGNED SITES-->
          <div class="is-2-columns">
            <div class="tab is-flex justify-between">
              <div class="">Assigned To {{ Naming.User }} Persona</div>
              <div v-if="hasAddedSites">
                Showing {{ this.addedSites.length }} added {{ Naming.Sites }}
              </div>
            </div>
            <draggable :list="addedSites" group="subAssetSelector"
                       class="sub-asset-draggable has-background-white-bis is-fullheight"
                       handle="#handle"
            >
              <div v-if="hasAddedSites" v-for="(site, index) in addedSites" :key="site.id">
                <assigned-site-item :available-items="false" :index="index"
                                    :site="site" :staged="addedSites"
                                    @removeFromSelected="removeSiteFromAddedSites"/>
              </div>
              <div v-if="!hasAddedSites" class="has-text-grey p-1">No {{ Naming.Sites }} yet. Select or drag from
                available {{ Naming.Sites }}.
              </div>
            </draggable>
          </div>

          <!--AVAILABLE SITES-->
          <div class="is-3-columns">
            <div class="tab available is-flex justify-between">
              <div class="">Available {{ Naming.Sites }}
              </div>
              <div v-if="!filtering">
                Showing {{ availableSitesList.length }} of {{ calculatedTotalSites }}
              </div>
              <div v-else>
                Showing {{ availableSitesList.length }} Filtered {{ Naming.Sites}}
              </div>
            </div>

            <div>
              <div class="search-box grid has-4-columns gap-1">
                <div class="is-2-columns">
                  <data-selector
                      class="is-marginless"
                      searchable
                      v-model="available_client_id"
                      @input="searchAvailableClientSites"
                      left-icon="address-book"
                      label-key="legal_name"
                      value-key="id"
                      :items="clientList"
                      :prompt-label="`Filter by ${Naming.Client}`"
                      :on-type="searchClient"
                      :on-lose-focus="clearAvailableSitesClientFilter">
                    <action-button
                        v-if="available_client_id"
                        slot="right"
                        class="is-light"
                        @click="clearAvailableSitesClientFilter()">
                      <icon icon="times"/>
                    </action-button>
                  </data-selector>
                </div>
                <div class="is-flex is-2-columns">
                  <input
                      type="text"
                      v-model="availableSitesSearch"
                      class="input"
                      :placeholder="`Search by ${Naming.Site.toLocaleLowerCase()} name`"
                  />
                  <div class="is-flex">
                    <action-button
                        v-if="availableSitesSearch"
                        @click="clearAvailableSitesSearch()"
                        class="is-info-lighter">
                      <span>Clear</span>
                      <icon icon="times"/>
                    </action-button>
                  </div>
                  <div class="is-flex">
                    <action-button @click="searchAvailableSites()" class="ml-1 is-info-lighter">Search</action-button>
                  </div>
                </div>
              </div>
              <div class="search-box grid has-3-columns gap-1">
                <div class="is-flex">
                  <action-button left-icon="plus" :disabled="loadingMoreSites" @click="selectAll()" class="is-info-lighter">Select All Showing {{ Naming.Sites }}</action-button>
                  <action-button left-icon="sort" @click="sortSites('name')">Site Name</action-button>
                  <action-button left-icon="sort" @click="sortSites('client')">Client Name</action-button>
                </div>
              </div>

              <draggable :list="availableSitesList" group="subAssetSelector" class="sub-asset-draggable">
                <div v-for="(availableSite, index) in this.availableSitesList" :key="availableSite.id">
                  <site-item v-if="!loadingMoreSites" :is-available-items="true"
                             :index="index" class="available-item"
                             :site="availableSite" @pushToSelected="pushSiteToSelected"/>
                </div>
                <loader v-if="loadingMoreSites" v-bind="{ loadingMoreSites }"
                        :text="`Loading More ${Naming.Sites}`"></loader>
                <div v-if="moreSitesAvailable">
                  <div
                      class="persona-load-more-box has-text-grey is-flex-column align-items-center justify-between"
                      @click="loadMoreSites()"
                  >
                    <div class="has-text-grey has-text-centered p-05" v-if="!filtering">
                      Showing {{ this.availableSitesList.length }} of {{ calculatedTotalSites }}
                    </div>
                    <div v-else>
                      Show More Filtered {{Naming.Sites}}
                    </div>
                    <icon class="is-size-5" icon="plus-circle"></icon>
                  </div>
                </div>
                <!--placeholder to give height when no subasset items-->
                <div class="p-2">&nbsp</div>
              </draggable>
              <div v-if="!availableSitesList">
                <div class="has-text-grey p-05">No additional available {{ Naming.Sites.toLowerCase() }}.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </form>

  </page>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import ChangeZone from "@/views/asset/manager/partials/ChangeZone.vue"
import SiteItem from "@/views/user/personas/partials/SiteItem.vue"
import {common as backend} from "@/api"
import AssignedSiteItem from "@/views/user/personas/partials/AssignedSiteItem.vue"
import Search from "@/utils/search"

export default {
  components: {AssignedSiteItem, SiteItem, ChangeZone},

  data: () => ({
    loading: false,
    availableSitesSearch: '',
    available_client_id: '',
    loadingSites: false,
    loadingMoreSites: false,
    sortDescending: {
      name: false,
      created_at: false,
    },
    addingAllSites: false,
    processingTextSiteCountLimit: 1000,
  }),

  async created() {
    await this.loadAllSites()
    if (this.available_client_id ) {
      await this.$store.dispatch('client/searchClient', { id: parseInt(this.available_client_id) })
    } else {
      Search.searchByUri('', 'client/searchClient', this.$store, 0)
    }
  },

  beforeDestroy() {
    this.clearUserPersona()
  },

  methods: {
    ...mapMutations('userPersonas', [
      'clearUserPersona',
      'name',
      'sites',
    ]),
    ...mapMutations('client', [
      'setClientList',
    ]),
    searchClient(text) {
      Search.debouncedSearchByUri(text, 'client/searchClient', this.$store)
    },
    clearAvailableSitesClientFilter() {
      this.available_client_id = ''
      this.availableSites.data = []
      this.loadAllSites()
    },
    store() {
      this.loading = true
      let payload = {
        name: this.persona.name,
        added_sites: this.addedSites.map((site) => {
          return {site_id: site.id}
        })
      }
      this.$store.dispatch('userPersonas/store', payload).then(userPersona => {
        this.$toast.success(this.Convert(this.$lang.userPersona.created))
        this.loading = false
        this.$router.back()
      }).catch(error => {
        this.loading = false
        if(error.response.status === 422) {
          this.$toast.warning("The Name already exists for this Persona.")
        }else {

          this.$whoops()
        }
      })
    },
    searchAvailableClientSites() {
      this.loadingMoreSites = true
      this.availableSites.data = []
      const queryParams = this.getPayload()
      backend.loadPath(
          {
            path: this.availableSites.links.first,
            params: queryParams,
          },
          ({ data }) => {
            this.availableSites.links.next = data.links.next
            this.availableSites.meta = data.meta
            this.availableSites.data.push(...data.data)
          },
          error => {
            console.error('Error fetching available sites:', error)
          }
      ).finally(() => {
        this.loadingMoreSites = false
      })
    },
    async searchAvailableSites() {
      this.loadingMoreSites = true
      this.availableSites.data = []

      const queryParams = this.getPayload()
      await backend.loadPath(
          {
            path: this.availableSites.links.first,
            params: queryParams,
          },
          ({ data }) => {
            this.availableSites.links.next = data.links.next
            this.availableSites.meta = data.meta
            this.availableSites.data.push(...data.data)
          },
          error => {
            console.error('Error fetching available sites:', error)
          }
      ).finally(() => {
        this.loadingMoreSites = false
      })
    },
    async loadAllSites() {
      try {
        this.loadingMoreSites = true
        const payload = this.getPayload()
        await this.$store.dispatch('userPersonas/loadAllSites', payload)
      } catch (error) {
        console.log(error)
        this.$whoops()
      } finally {
        this.loadingMoreSites = false
      }
    },
    getPayload() {
      return {
        available_sites_search: this.availableSitesSearch ?? '',
        available_client_id: this.available_client_id,
      }
    },
    removeSiteFromAddedSites(site) {
      const addedIndex = this.addedSites.findIndex(addedSite => addedSite.id === site.id)
      return this.addedSites.splice(addedIndex, 1)
    },
    clearAvailableSitesSearch() {
      this.availableSitesSearch = ''
      this.loadAllSites()
    },
    async selectAll() {
      this.availableSites.data.forEach(site => {
        const alreadyAdded = this.addedSites.some(addedSite => addedSite.id === site.id)
        if (!alreadyAdded) {
          this.addedSites.push(site)
        }
      })
      if (this.moreSitesAvailable) {
        return this.loadMoreSites()
      }
    },
    async addAllSites() {
      //  we need to mark the action as busy
      this.addingAllSites = true
      this.loadingSites = true
      this.loadingMoreSites = true

      await this.$store.dispatch('userPersonas/storeAllSites', {name:this.persona.name}).then(userPersona => {
        this.$toast.success(this.Convert(this.$lang.userPersona.created))
        this.loading = false
        this.$router.back()
      }).catch((error) => {
        console.log(error)
        this.$whoops()
      }).finally(() => {
        this.addingAllSites = false
        this.loadingSites = false
        this.loadingMoreSites = false
      })
    },
    sortSites(key) {
      let isDescending = this.sortDescending[key]
      this.availableSites.data = this.sortArray(this.availableSites?.data, key, isDescending)
      this.sortDescending[key] = !this.sortDescending[key]
    },
    sortArray(array, key, descending = false) {
      return array.sort((a,b) => {
        let valueA = a[key]
        let valueB = b[key]

        if(typeof valueA === 'string') {
          valueA = valueA?.toLowerCase()
          valueB = valueB?.toLowerCase()
        }

        return descending ? (valueB?.localeCompare(valueA)) : (valueA?.localeCompare(valueB))
      })
    },
    pushSiteToSelected(site) {
      this.addedSites.push(site)
    },
    loadMoreSites() {
      this.loadingMoreSites = true
      const payload = this.getPayload()
      const data = {
        path: this.availableSites.links.next,
        params: payload
      }
      backend.loadPath(
          data,
          ({ data }) => {
            this.availableSites.links.next = data.links.next
            this.availableSites.meta = data.meta
            this.availableSites.data.push(...data.data)
          },
          error => {
            console.error("Error loading more sites:", error)
          }
      ).finally(() => {
        this.loadingMoreSites = false
      })
    },
  },

  computed: {
    ...mapState('userPersonas', ['addedSites']),
    ...mapGetters('userPersonas', [
      'persona',
      'availableSites',
      'addedSites'
    ]),
    ...mapGetters('client', [
      'clientList'
    ]),
    availableSitesList() {
      return this.availableSites.data.filter(
          site => !this.addedSites.some(addedSite => addedSite.id === site.id)
      )
    },
    hasAddedSites() {
      return this.addedSites.length > 0
    },
    calculatedTotalSites() {
      let availableSitesCount = this.availableSites?.data?.length - this.addedSites.length
      if (this.moreSitesAvailable) {
        const toBeLoaded = this.availableSites?.meta.total - this.availableSites?.meta.to
        return availableSitesCount + toBeLoaded
      }
      return availableSitesCount
    },
    moreSitesAvailable() {
      return this.availableSites?.links?.next?.length > 0
    },
    filtering() {
      return this.availableSitesSearch || this.available_client_id
    },
    validPersonaNameEntered() {
      return this.persona.name.length > 2
    },
    canSave() {
      return this.validPersonaNameEntered && this.addedSites.length > 0
    },
    loadingText() {
      if(this.addedSites.length > this.processingTextSiteCountLimit) {
        return `Processing ${this.addedSites.length} ${ this.Naming.Sites }, this may take a while.`
      }

      return "Loading..."
    },
  }
}
</script>
